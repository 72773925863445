.mb-15 {
  margin-bottom: 15px;
}

.container-laboratorio {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 15px;
}
